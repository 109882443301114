import React from 'react';
import logo from '../images/logo.png'; // replace with the path to your logo file
import { Link } from 'react-router-dom';

function Header() {
    return (
        <><header className="bg-emerald-500 text-white text-lg p-4 flex flex-col md:flex-row items-center justify-between">
            <div className="flex items-center">
                <img src={logo} alt="BelenCutsForKids Logo" className="mr-2 h-20" />
                <h1 className="font-bold text-2xl">Belen Cuts For Kids</h1>
            </div>
            <nav>
                <ul className="flex space-x-4 justify-center">
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/services">Services</Link></li>
                    <li><Link to="/about">About</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                </ul>
            </nav>
        </header>
        </>

    );
}

export default Header;