import React from 'react';
import profileImage from '../images/profile.png';
import { Analytics } from "@vercel/analytics/react"

function About() {
    return (
        <div className="flex flex-col items-center justify-center">
            <div className="p-4 flex flex-col items-center">
                <img src={profileImage} alt="Profile" className="w-80  mb-6 p-1" />
                <div>
                    <h1 className="text-2xl pt-8 leading-relaxed font-extrabold text-gray-700 dark:text-gray-300 p-2">About Belen Cuts ForKids</h1>
                    <p className="text-2xl leading-relaxed font-medium text-gray-700 dark:text-gray-300 p-10">At BELEN Cuts for Kids, we offer safe and welcoming haircuts to families in Central Florida. We pride ourselves on being inclusive and serving families of all types and sizes. Our founder and stylist, Melinda, has encountered numerous families who face similar challenges. Some simply don't have the time, while others have loved ones with limitations or sensory issues that make getting a haircut difficult. For some, the traditional salon environment can be overwhelming, and the tools used for haircuts can be scary. As a result, many family members go without proper hair care. We're here to change that..</p>
                </div>

                <div>
                    <h1 className="text-2xl pt-8 leading-relaxed font-extrabold text-gray-700 dark:text-gray-300 p-2">Founder of BELEN Cuts for Kids</h1>
                    <p className="text-2xl leading-relaxed font-medium text-gray-700 dark:text-gray-300 p-5">Hello everyone, I am Ms. Mel. Many people may describe me as energetic, patient, and optimistic. I have been working in the hair care business for over 17yrs. I fell in love with the industry because of the freedom of expression in the art of hair. </p>.

                    <p className="text-2xl leading-relaxed font-medium text-gray-700 dark:text-gray-300 p-5">
                        A few years ago, I started working for a salon catering to children, not adults. To my amazement, I instantly fell in love with the kids and families coming in. As a parent, you have seen the good, the bad, the misunderstood and the ugly. I cater to all children, big or small, and <p className="font-bold">specialize in working with children with special needs and processing disorders. </p>
                    </p>

                    <p className="text-2xl leading-relaxed font-medium text-gray-700 dark:text-gray-300 p-5">
                        My ambition grew when I realized I needed to make the children feel safe and comfortable while receiving their haircuts. I decided to lend a hand and start tHairapy. Not "therapy" but t"Hair"apy. I am not a licensed therapist but a licensed cosmetologist who knows what your hair needs. I take on different approaches to fit clients’ needs and comfort. I use toys, music, patience, and skill at each session.
                    </p>

                    <p className="text-2xl leading-relaxed font-medium text-gray-700 dark:text-gray-300 p-5">
                        A haircut is not just a haircut with Ms. Mel; it’s playtime so all can enjoy it.
                    </p>


                </div>

            </div>
            <Analytics />
        </div>

    );
}

export default About;
