import React from 'react';

function Footer() {
    return (
        <footer className="bg-emerald-500 text-white text-lg p-4 flex flex-col md:flex-row items-center justify-between">
            <p>&copy; 2023 Belen Cuts For Kids</p>
            <p>Developed by <a href="https://www.linkedin.com/in/josephdrivera15/" target="_blank" rel="noreferrer" className="underline">Joseph D Rivera</a></p>

        </footer>
    );
}

export default Footer;