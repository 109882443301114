import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Analytics } from "@vercel/analytics/react"
import image1 from '../images/Carousel_01.png';
import image2 from '../images/Carousel_02.png';
import image3 from '../images/Carousel_03.png';
import image4 from '../images/Carousel_04.png';
import image5 from '../images/Carousel_05.png';
import image6 from '../images/Carousel_06.png';
import image7 from '../images/Carousel_07.png';
import image8 from '../images/Carousel_08.png';
import image9 from '../images/Carousel_09.png';
import image10 from '../images/Carousel_10.png';
import image11 from '../images/Carousel_11.png';
import image12 from '../images/Carousel_12.png';

function Home() {
    return (
        <div className="p-4">
            <br />
            <div className="relative flex flex-col lg:flex-row space-y-16 lg:space-y-0 text-center lg:text-left container xl:max-w-7xl mx-auto px-4 py-16 lg:px-8 lg:py-32">
                <div className="lg:w-1/2 lg:flex lg:items-center">
                    <div>
                        <h1 className="text-4xl font-black text-black mb-4 dark:text-white">
                            Welcome to <span className="text-emerald-500 dark:text-emerald-500"> Belen Cuts For Kids!</span>
                        </h1>
                        <h2 className="text-xl leading-relaxed font-medium text-gray-700 dark:text-gray-300">
                            At BELEN Cuts for Kids, we offer a convenient solution - we can come to you! Just honk or knock, and we'll arrive at your door. We are experienced stylists and great with children and strive to make every visit memorable. We aim to transform haircuts into a fun-filled event.
                        </h2>
                        <div className="flex flex-col sm:flex-row sm:items-center justify-center lg:justify-start space-y-2 sm:space-y-0 sm:space-x-2 pt-10 pb-16">
                            <a href="/contact" className="inline-flex justify-center items-center space-x-2 border font-semibold rounded-lg px-7 py-3.5 leading-6 border-emerald-700 bg-emerald-700 text-white hover:text-white hover:bg-emerald-600 hover:border-emerald-600 focus:ring focus:ring-emerald-400 focus:ring-opacity-50 active:bg-emerald-700 active:border-emerald-700 dark:focus:ring-emerald-400 dark:focus:ring-opacity-90">
                                <svg className="hi-mini hi-mail inline-block w-5 h-5 opacity-75" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path d="M18 4H2a2 2 0 00-2 2v8a2 2 0 002 2h16a2 2 0 002-2V6a2 2 0 00-2-2zM2 6h16v1.586l-8 6.764-8-6.764V6zm16 8H2v-5.586l8 6.764 8-6.764V14z" /></svg>
                                <span>Contact us</span>
                            </a>
                            <a href="/services" className="inline-flex justify-center items-center space-x-2 border font-semibold rounded-lg px-7 py-3.5 leading-6 border-gray-200 bg-white text-gray-800 hover:border-gray-300 hover:text-gray-900 hover:shadow-sm focus:ring focus:ring-gray-300 focus:ring-opacity-25 active:border-gray-200 active:shadow-none dark:border-gray-700 dark:bg-transparent dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-gray-200 dark:focus:ring-gray-600 dark:focus:ring-opacity-40 dark:active:border-gray-700">
                                <span>Our Services</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="lg:w-1/2 lg:ml-16 lg:flex lg:justify-center lg:items-center">
                    <div className="relative mx-5 lg:w-96">
                        <div className="absolute top-0 left-0 w-40 h-40 lg:w-72 lg:h-72 bg-tranparent border border-blue-200 rounded-full -mt-16 -ml-20 dark:border-emerald-900" />
                        <div className="absolute top-0 left-0 w-40 h-40 lg:w-72 lg:h-72 bg-tranparent border border-blue-100 rounded-full -mt-20 -ml-14 dark:border-emerald-950" />
                        <div className="absolute bottom-0 right-0 w-40 h-40 lg:w-72 lg:h-72 bg-tranparent border border-blue-200 rounded-full -mb-16 -mr-20 dark:border-emerald-900" />
                        <div className="absolute bottom-0 right-0 w-40 h-40 lg:w-72 lg:h-72 bg-tranparent border border-blue-100 rounded-full -mb-20 -mr-14 dark:border-emerald-950" />
                        <div className="absolute inset-0 rounded-xl -m-6 -rotate-2 bg-gray-200 dark:bg-gray-800" />
                        <div className="absolute inset-0 rounded-xl -m-6 rotate-1 bg-emerald-800 bg-opacity-75 shadow-inner dark:bg-emerald-800 dark:bg-opacity-75" />
                        <section className="py-20">
                            <Carousel showThumbs={false} showStatus={false} autoPlay infiniteLoop>
                                <div>
                                    <img src={image12} alt="First carousel display" />
                                </div>
                                <div>
                                    <img src={image2} alt="Second carousel display" />
                                </div>
                                <div>
                                    <img src={image3} alt="Third carousel display" />
                                </div>
                                <div>
                                    <img src={image4} alt="Fourth carousel display" />
                                </div>
                                <div>
                                    <img src={image5} alt="Fifth carousel display" />
                                </div>
                                <div>
                                    <img src={image6} alt="Sixth carousel display" />
                                </div>
                                <div>
                                    <img src={image7} alt="Seventh carousel display" />
                                </div>
                                <div>
                                    <img src={image8} alt="Eighth carousel display" />
                                </div>
                                <div>
                                    <img src={image9} alt="Ninth carousel display" />
                                </div>
                                <div>
                                    <img src={image10} alt="Tenth carousel display" />
                                </div>
                                <div>
                                    <img src={image11} alt="Eleventh carousel display" />
                                </div>
                                <div>
                                    <img src={image1} alt="Twelfth carousel display" />
                                </div>
                            </Carousel>
                        </section>
                    </div>
                </div>
            </div>
            <Analytics />
        </div >
    );
}

export default Home;
