import React from 'react';
import Card from '../components/Card';
import { Analytics } from "@vercel/analytics/react"

const servicesData = [
    {
        title: "Child Haircut",
        description: "$35",
    },
    {
        title: "tHairapy (Sensory Friendly Cut)",
        description: "$45(20-45min) - $65(45-60min)",
    },
    {
        title: "Mommy Haircuts and light Blow-dry",
        description: "$50",
    },
    {
        title: "Daddy Haircut",
        description: "$45",
    },
    {
        title: "Blow-dry",
        description: "$15 and up",
    },
    {
        title: "Curls",
        description: "$15 and up",

    },
    {
        title: "Princess Braids",
        description: "$15 and up",
    },

];



function Services() {
    return (
        <div className="p-4 flex justify-center">
            <div className="font-medium">
                <h2 className="text-orange-500 text-2xl mb-2 font-bold">Our Services</h2>
                <p className="font-bold"> Group Haircuts of 8 or more will receive a discount of $5 off original price per person. </p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {servicesData.map((service, index) => (
                        <Card
                            key={index}
                            title={service.title}
                            description={service.description}
                            image={service.image}
                        />
                    ))}
                </div>
            </div>
            <Analytics />
        </div>
    );
}


export default Services;
