import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { Analytics } from "@vercel/analytics/react"

// Define the useFormState hook
const useFormState = (initialState) => {
    const [formData, setFormData] = useState(initialState);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const resetForm = () => {
        setFormData(initialState);
    };

    return [formData, handleChange, resetForm];
};

function Contact() {
    const initialState = {
        firstName: '',
        lastName: '',
        email: '',
        services: '',
        message: '',
        phone: '',
        date: '',
        time: '',
        partySize: '',
        location: '',
        SocialMedia: '',
        sensoryChallenges: '',
    };

    const [formData, handleChange, resetForm] = useFormState(initialState);

    const EMAILJS_USER_ID = process.env.REACT_APP_EMAILJS_USER_ID;
    const EMAILJS_SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const EMAILJS_TEMPLATE_ID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;

    const handleSubmit = (event) => {
        event.preventDefault();

        // Add a conditional check to prevent sending email if any required field is empty
        if (!formData.firstName || !formData.lastName || !formData.email || !formData.services || !formData.message || !formData.phone || !formData.date || !formData.time || !formData.partySize || !formData.location || !formData.SocialMedia) {
            alert('Please fill out all required fields.');
            return;
        }

        sendEmail(event);
    };

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, e.target, EMAILJS_USER_ID)
            .then(
                (result) => {
                    console.log(result.text);
                    alert('Email sent successfully!'); // Add a success message
                },
                (error) => {
                    console.log(error.text);
                    alert('Error sending email. Please try again.'); // Add an error message
                }
            );
        e.target.reset();
    };

    const handleReset = () => {
        resetForm(); // Call the resetForm function to reset the form data to the initial state
    };

    return (
        <div className="bg-cover" style={{ backgroundImage: 'url("https://www.kidscastlecuts.com/wp-content/uploads/2020/07/KidsCut-36-scaled.jpg")' }}>
            <div className="bg-emerald-900 bg-opacity-95">
                <div className="container xl:max-w-7xl mx-auto px-4 py-16 lg:px-8 lg:py-32">
                    <div className="flex flex-col lg:flex-row lg:items-center space-y-16 lg:space-y-0 lg:space-x-16">
                        <div className="lg:w-2/5">
                            <h2 className="text-4xl font-black text-white mb-4">
                                Get in touch
                            </h2>
                            <h3 className="text-xl leading-relaxed font-medium text-gray-200">
                                We'd love to hear from you! Whether you have a question about our services or want to make a booking, feel free to get in touch.
                            </h3>
                        </div>
                        <div className="flex flex-col rounded-xl shadow-sm bg-white/10 p-2 overflow-hidden dark:text-gray-100 lg:w-1/2 lg:mx-auto">
                            <form onSubmit={handleSubmit} className="p-5 md:p-10 space-y-6 bg-white rounded-lg dark:bg-gray-800">
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                                    <div className="space-y-1">
                                        <label htmlFor="firstName" className="font-medium">First Name</label>
                                        <input
                                            type="text"
                                            id="firstName"
                                            name="firstName"
                                            value={formData.firstName}
                                            onChange={handleChange}
                                            className="w-full block border placeholder-gray-500 px-5 py-3 leading-6 rounded-lg border-gray-200 focus:border-emerald-500 focus:ring focus:ring-emerald-500 focus:ring-opacity-50 dark:bg-gray-800 dark:border-gray-600 dark:focus:border-emerald-500 dark:placeholder-gray-400"
                                            required
                                        />
                                    </div>
                                    <div className="space-y-1">
                                        <label htmlFor="lastName" className="font-medium">Last Name</label>
                                        <input
                                            type="text"
                                            id="lastName"
                                            name="lastName"
                                            value={formData.lastName}
                                            onChange={handleChange}
                                            className="w-full block border placeholder-gray-500 px-5 py-3 leading-6 rounded-lg border-gray-200 focus:border-emerald-500 focus:ring focus:ring-emerald-500 focus:ring-opacity-50 dark:bg-gray-800 dark:border-gray-600 dark:focus:border-emerald-500 dark:placeholder-gray-400"
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="email" className="font-medium">Email</label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        className="w-full block border placeholder-gray-500 px-5 py-3 leading-6 rounded-lg border-gray-200 focus:border-emerald-500 focus:ring focus:ring-emerald-500 focus:ring-opacity-50 dark:bg-gray-800 dark:border-gray-600 dark:focus:border-emerald-500 dark:placeholder-gray-400"
                                        required
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="phone" className="font-medium">Phone</label>
                                    <input
                                        type="tel"
                                        id="phone"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        className="w-full block border placeholder-gray-500 px-5 py-3 leading-6 rounded-lg border-gray-200 focus:border-emerald-500 focus:ring focus:ring-emerald-500 focus:ring-opacity-50 dark:bg-gray-800 dark:border-gray-600 dark:focus:border-emerald-500 dark:placeholder-gray-400"
                                        required
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="date" className="font-medium">Date</label>
                                    <input
                                        type="date"
                                        id="date"
                                        name="date"
                                        value={formData.date}
                                        onChange={handleChange}
                                        className="w-full block border placeholder-gray-500 px-5 py-3 leading-6 rounded-lg border-gray-200 focus:border-emerald-500 focus:ring focus:ring-emerald-500 focus:ring-opacity-50 dark:bg-gray-800 dark:border-gray-600 dark:focus:border-emerald-500 dark:placeholder-gray-400"
                                        required
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="time" className="font-medium">Time</label>
                                    <input
                                        type="time"
                                        id="time"
                                        name="time"
                                        value={formData.time}
                                        onChange={handleChange}
                                        className="w-full block border placeholder-gray-500 px-5 py-3 leading-6 rounded-lg border-gray-200 focus:border-emerald-500 focus:ring focus:ring-emerald-500 focus:ring-opacity-50 dark:bg-gray-800 dark:border-gray-600 dark:focus:border-emerald-500 dark:placeholder-gray-400"
                                        required
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="partySize" className="font-medium">Party Size</label>
                                    <select
                                        id="partySize"
                                        name="partySize"
                                        value={formData.partySize}
                                        onChange={handleChange}
                                        className="w-full block border px-5 py-3 leading-6 rounded-lg border-gray-200 focus:border-emerald-500 focus:ring focus:ring-emerald-500 focus:ring-opacity-50 dark:bg-gray-800 dark:border-gray-600 dark:focus:border-emerald-500"
                                        required
                                    >
                                        <option value="">Select a party size</option>
                                        <option value="1">1 person</option>
                                        <option value="2">2 people</option>
                                        <option value="3">3 people</option>
                                        <option value="4">4 people</option>
                                        <option value="5">5 people</option>
                                        <option value="6">6 people</option>
                                        <option value="7">7 people</option>
                                        <option value="8">8 people</option>
                                        <option value="9">9 people</option>
                                        <option value="10">10 people</option>
                                    </select>
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="location" className="font-medium">Address/Service Area</label>
                                    <input
                                        type="text"
                                        id="location"
                                        name="location"
                                        value={formData.location}
                                        onChange={handleChange}
                                        className="w-full block border placeholder-gray-500 px-5 py-3 leading-6 rounded-lg border-gray-200 focus:border-emerald-500 focus:ring focus:ring-emerald-500 focus:ring-opacity-50 dark:bg-gray-800 dark:border-gray-600 dark:focus:border-emerald-500 dark:placeholder-gray-400"
                                        required
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="services" className="font-medium">Services</label>
                                    <select
                                        id="services"
                                        name="services"
                                        value={formData.services}
                                        onChange={handleChange}
                                        className="w-full block border px-5 py-3 leading-6 rounded-lg border-gray-200 focus:border-emerald-500 focus:ring focus:ring-emerald-500 focus:ring-opacity-50 dark:bg-gray-800 dark:border-gray-600 dark:focus:border-emerald-500"
                                        required
                                    >
                                        <option value="">Select a category</option>
                                        <option value="Haircuts and Styling">Child Haircut</option>
                                        <option value="Coloring and Highlights">tHairapy (Sensory Friendly Cut)</option>
                                        <option value="Hair Treatments">Mommy Haircuts and light Blow-dry</option>
                                        <option value="Special Event Packages">Daddy Haircut</option>
                                        <option value="Bridal Services">Blow-dry</option>
                                        <option value="Bridal Services">Curls</option>
                                        <option value="Bridal Services">Princess Braids</option>
                                    </select>
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="message" className="font-medium">Any sensory challenges I should know about?</label>
                                    <select
                                        id="message"
                                        name="message"
                                        value={formData.message}
                                        onChange={handleChange}
                                        className="w-full block border px-5 py-3 leading-6 rounded-lg border-gray-200 focus:border-emerald-500 focus:ring focus:ring-emerald-500 focus:ring-opacity-50 dark:bg-gray-800 dark:border-gray-600 dark:focus:border-emerald-500"
                                        required
                                    >
                                        <option value="">Select a category</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>
                                {formData.message === "Yes" && ( // Show the message box if formData.message is "Yes"
                                    <div className="space-y-1">
                                        <label htmlFor="sensoryChallenges" className="font-medium">Please tell us more about the sensory challenges:</label>
                                        <textarea
                                            id="sensoryChallenges"
                                            name="sensoryChallenges"
                                            value={formData.sensoryChallenges}
                                            onChange={handleChange}
                                            /**
                                            /BelenCutsForKids/src/pages/Contact.js
                                             * 
                                             * This file contains the Contact page component, which displays a form for users to submit a message to the website owner.
                                             * The form includes fields for name, email, phone number, message, and a select dropdown for whether the user allows the website owner to use their footage on social media.
                                             * The form also includes a submit button and a reset button.
                                             * If the user submits the form, the data is sent to the website owner's email address.
                                             * If the user clicks the reset button, the form fields are cleared.
                                             * If the user cancels or rebooks their appointment, there is a cancellation policy in place.
                                             * All fields are required to be filled out before submitting.
                                             * If the user is outside of the normal service area, there is a fee of $10.
                                             */
                                            rows={6}
                                            className="w-full block border placeholder-gray-500 px-5 py-3 leading-6 rounded-lg border-gray-200 focus:border-emerald-500 focus:ring focus:ring-emerald-500 focus:ring-opacity-50 dark:bg-gray-800 dark:border-gray-600 dark:focus:border-emerald-500 dark:placeholder-gray-400"
                                            required
                                        />
                                    </div>
                                )}
                                <div className="space-y-1">
                                    <p className="font-bold"> NOTE - There is a fee of $10 outside of normal service areas </p>
                                    <p className="font-bold">
                                        CANCELLATION POLICY
                                        If you must cancel or rebook your appointment, we respectfully request at least a 24- hour notice. Cancellations without 24-hour notice will result in a charge for part or all of the services you have requested.
                                    </p>
                                </div>
                                <p className="font-bold"> All fields are required to be filled out before submitting.
                                </p>
                                <div className="space-y-1">
                                    <label htmlFor="SocialMedia" className="font-medium">May I utilize the footage (such as photos or videos) available on our platform to assist my existing or potential friends, such as on social media?</label>
                                    <select
                                        id="SocialMedia"
                                        name="SocialMedia"
                                        value={formData.SocialMedia}
                                        onChange={handleChange}
                                        className="w-full block border px-5 py-3 leading-6 rounded-lg border-gray-200 focus:border-emerald-500 focus:ring focus:ring-emerald-500 focus:ring-opacity-50 dark:bg-gray-800 dark:border-gray-600 dark:focus:border-emerald-500"
                                        required
                                    >
                                        <option value="">Select a category</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>
                                <button
                                    type="submit"
                                    className="w-full inline-flex justify-center items-center space-x-2 border font-semibold rounded-lg px-8 py-4 leading-6 border-emerald-700 bg-emerald-700 text-white hover:text-white hover:bg-emerald-600 hover:border-emerald-600 focus:ring focus:ring-emerald-400 focus:ring-opacity-50 active:bg-emerald-700 active:border-emerald-700 dark:focus:ring-emerald-400 dark:focus:ring-opacity-90"
                                >
                                    <svg
                                        className="hi-mini hi-paper-airplane inline-block w-5 h-5 opacity-50"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path d="M3.105 2.289a.75.75 0 00-.826.95l1.414 4.925A1.5 1.5 0 005.135 9.25h6.115a.75.75 0 010 1.5H5.135a1.5 1.5 0 00-1.442 1.086l-1.414 4.926a.75.75 0 00.826.95 28.896 28.896 0 0015.293-7.154.75.75 0 000-1.115A28.897 28.897 0 003.105 2.289z" />
                                    </svg>
                                    <span>Send Message</span>

                                </button>
                                <button
                                    type="button"
                                    onClick={handleReset}
                                    className="w-full inline-flex justify-center items-center space-x-2 border font-semibold rounded-lg px-8 py-4 leading-6 border-gray-500 bg-white text-gray-600 hover:text-gray-700 hover:bg-gray-100 hover:border-gray-700 focus:ring focus:ring-gray-400 focus:ring-opacity-50 active:bg-gray-200 active:border-gray-700 dark:focus:ring-gray-400 dark:focus:ring-opacity-90"
                                >
                                    <svg
                                        className="hi-mini hi-refresh inline-block w-5 h-5 opacity-50"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                    </svg>
                                    <span>Reset</span>
                                </button>
                            </form>
                        </div>
                    </div>
                </div >
            </div >
            <Analytics />
        </div >
    );
}

export default Contact;
