import React from 'react';
import { useNavigate } from 'react-router-dom';

function Card({ title, description, image }) {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/contact');
    }

    return (
        <div className="flex flex-col rounded-lg shadow-lg hover:shadow-2xl bg-white overflow-hidden dark:text-gray-100 dark:bg-gray-800 sm:flex-row transition-shadow duration-500 ease-in-out">
            <div className="p-5 grow sm:w-2/3">
                <h3 className="font-semibold text-xl mb-1">
                    {title}
                </h3>
                <p className="text-sm font-medium text-gray-600 dark:text-gray-400">
                    {description}
                </p>
                <div className="flex justify-between items-center mt-4">
                    <button type="button" className="inline-flex justify-center items-center space-x-2 border font-semibold rounded-lg px-3 py-2 leading-5 text-sm border-gray-200 bg-white text-gray-800 hover:border-gray-300 hover:text-gray-900 hover:shadow-sm focus:ring focus:ring-gray-300 focus:ring-opacity-25 active:border-gray-200 active:shadow-none dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-gray-200 dark:focus:ring-gray-600 dark:focus:ring-opacity-40 dark:active:border-gray-700" onClick={handleButtonClick}>
                        <ContactIcon />
                        <span>Contact Us</span>
                    </button>
                </div>
            </div>
        </div>
    );
}

const ContactIcon = () => (
    <svg className="hi-mini hi-mail inline-block w-4 h-4 opacity-50" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path fillRule="evenodd" d="M2 5a2 2 0 012-2h12a2 2 0 012 2v1.586A2 2 0 0015.586 7L10 12.586 4.414 7A2 2 0 002 6.586V5zm0 2v8a2 2 0 002 2h12a2 2 0 002-2V7a1.994 1.994 0 00-.293-.707l-7.293 7.293a1 1 0 01-1.414 0L2.293 6.293A1.994 1.994 0 002 7z" clipRule="evenodd" />
    </svg>
);

export default Card;
